import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../../components/layout'
import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

import statisticsImage from '../../assets/old-images/statistics/notebook.png'
import realtimeStatsImage from '../../assets/old-images/statistics/instant-feedback.png'
import historicalStatsImage from '../../assets/old-images/statistics/historic-stats.png'
import streamRestrictionsImage from '../../assets/old-images/statistics/listener-restrictions.png'

class Statistics extends React.Component {
  render() {
    return (
      <Page
        className={classNames('statistics', this.props.className)}
        headerTitle="Powerful Statistics"
        headerSubtitle="Get the information you need to make informed decisions."
        headerContent={headerContent}
      >
        <PageSection className={classNames('realtime-statistics')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Realtime Statistics
                </h1>
                <p className={classNames('section-text')}>
                  Our statistics platform allows station managers and DJs to
                  track their listeners in real time and drill down on exactly
                  who’s tuning in.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={realtimeStatsImage}
                alt="Live365 realtime statistics platform"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames('historical-statistics')}
          style={{ backgroundColor: '#F7F7F7' }}
        >
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('reverse-row', 'padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Historical Analytics
                </h1>
                <p className={classNames('section-text')}>
                  Listener analytics give you a view into your audience. Station
                  managers can look back on their week to see how shows and
                  playlists are performing.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={historicalStatsImage}
                alt="Live365 historical analytics platform"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection className={classNames('listener-restrictions')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Listener Restrictions
                </h1>
                <p className={classNames('section-text')}>
                  Live365 provides you total control over your station
                  listening. With Geoblocking, IP Banning, and Session Duration
                  settings you can tailor your audience and listening as you
                  please.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={streamRestrictionsImage}
                alt="Live365 stream settings manager"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

const headerContent = (
  <div className="row">
    <img src={statisticsImage} alt="Statistics with Live365" />
  </div>
)

export default Statistics
